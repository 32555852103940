import React, {Component} from 'react';
import _ from 'lodash';
import {Properties} from "../../common/properties";
import {UserMetaUtils} from "../../common/utils";
import {Section} from "../../components/SidebarSection";


class MoneySidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
        };
    }

    hasMetric(product, metric, frequencyBit) {
        return Boolean(this.props.userMeta.meta_data[product] &&
            UserMetaUtils.getMetaMetricKey(metric, frequencyBit) in this.props.userMeta.meta_data[product].metrics);
    }

    getProducts() {
        let products = [];
        _.forEach(this.props.userMeta.data, (productConfig) => {
            if (_.includes(['money_api'], productConfig.product_group)) {
                products.push(productConfig);
            }
        });
        return products;
    }

    makeLinks(moneyConfig) {
        return _.filter([
            {
                config: moneyConfig, metric: 'balance',
                frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Balance',
                metricGroup: 'Balance',
            },
            {
                config: moneyConfig, metric: 'balance',
                frequencyBit: Properties.frequencyBit.daily, label: 'Balance',
                metricGroup: 'Balance',
            },
            {
                config: moneyConfig, metric: 'loan',
                frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Loan',
                metricGroup: 'Loan',
            },
            {
                config: moneyConfig, metric: 'loan',
                frequencyBit: Properties.frequencyBit.daily, label: 'Loan',
                metricGroup: 'Loan',
            },
            {
                config: moneyConfig, metric: 'treasure',
                frequencyBit: Properties.frequencyBit.daily, label: 'Treasure',
                metricGroup: 'Treasure',
            },
            {
                config: moneyConfig, metric: 'deposit',
                frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Deposit Balance',
                metricGroup: 'Deposit',
            },
            {
                config: moneyConfig, metric: 'deposit',
                frequencyBit: Properties.frequencyBit.daily, label: 'Deposit Balance',
                metricGroup: 'Deposit',
            },
            {
                config: moneyConfig, metric: 'deposit_change',
                frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Deposit Change',
                metricGroup: 'Deposit',
            },
            {
                config: moneyConfig, metric: 'deposit_change',
                frequencyBit: Properties.frequencyBit.daily, label: 'Deposit Change',
                metricGroup: 'Deposit',
            },
            {
                config: moneyConfig, metric: 'txn',
                frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Deposit Txn',
                metricGroup: 'Deposit',
            },
            {
                config: moneyConfig, metric: 'txn',
                frequencyBit: Properties.frequencyBit.daily, label: 'Deposit Txn',
                metricGroup: 'Deposit',
            },
            {
                config: moneyConfig, metric: 'tpv',
                frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Deposit TPV',
                metricGroup: 'Deposit',
            },
            {
                config: moneyConfig, metric: 'tpv',
                frequencyBit: Properties.frequencyBit.daily, label: 'Deposit TPV',
                metricGroup: 'Deposit',
            },
            {
                config: moneyConfig, metric: 'loan_outstanding',
                frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Loan Outstanding',
                metricGroup: 'Loan',
            },
            {
                config: moneyConfig, metric: 'loan_outstanding',
                frequencyBit: Properties.frequencyBit.daily, label: 'Loan Outstanding',
                metricGroup: 'Loan',
            },
            {
                config: moneyConfig, metric: 'loan_outstanding_change',
                frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Loan Outstanding Change',
                metricGroup: 'Loan',
            },
            {
                config: moneyConfig, metric: 'loan_outstanding_change',
                frequencyBit: Properties.frequencyBit.daily, label: 'Loan Outstanding Change',
                metricGroup: 'Loan',
            },
            {
                config: moneyConfig, metric: 'loan_disbursement',
                frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Loan Disbursement',
                metricGroup: 'Loan',
            },
            {
                config: moneyConfig, metric: 'loan_disbursement',
                frequencyBit: Properties.frequencyBit.daily, label: 'Loan Disbursement',
                metricGroup: 'Loan',
            },
            {
                config: moneyConfig, metric: 'loan_repayment',
                frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Loan Repayment',
                metricGroup: 'Loan',
            },
            {
                config: moneyConfig, metric: 'loan_repayment',
                frequencyBit: Properties.frequencyBit.daily, label: 'Loan Repayment',
                metricGroup: 'Loan',
            },
            {
                config: moneyConfig, metric: 'cumulative_borrower',
                frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Cumulative Borrowers',
                metricGroup: 'Loan',
            },
            {
                config: moneyConfig, metric: 'cumulative_borrower',
                frequencyBit: Properties.frequencyBit.daily, label: 'Cumulative Borrowers',
                metricGroup: 'Loan',
            },
            {
                config: moneyConfig, metric: 'new_borrower',
                frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime New Borrowers',
                metricGroup: 'Loan',
            },
            {
                config: moneyConfig, metric: 'new_borrower',
                frequencyBit: Properties.frequencyBit.daily, label: 'New Borrowers',
                metricGroup: 'Loan',
            },
            {
                config: moneyConfig, metric: 'outstanding_borrower',
                frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Outstanding Borrowers',
                metricGroup: 'Loan',
            },  
            {
                config: moneyConfig, metric: 'outstanding_borrower',
                frequencyBit: Properties.frequencyBit.daily, label: 'Outstanding Borrowers',
                metricGroup: 'Loan',
            },
            {
                config: moneyConfig, metric: 'investment_aua',
                frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime Investment AUA',
                metricGroup: 'Investment',
            },
            {
                config: moneyConfig, metric: 'investment_aua',
                frequencyBit: Properties.frequencyBit.daily, label: 'Investment AUA',
                metricGroup: 'Investment',
            },
            {
                config: moneyConfig, metric: 'investment_aua_change',
                frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime AUA Change',
                metricGroup: 'Investment',
            },
            {
                config: moneyConfig, metric: 'investment_aua_change',
                frequencyBit: Properties.frequencyBit.daily, label: 'AUA Change',
                metricGroup: 'Investment',
            },
            {
                config: moneyConfig, metric: 'new_customer',
                frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime New Customer',
                metricGroup: 'New User',
            },
            {
                config: moneyConfig, metric: 'new_customer',
                frequencyBit: Properties.frequencyBit.daily, label: 'New Customer',
                metricGroup: 'New User',
            },
            {
                config: moneyConfig, metric: 'new_register',
                frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime New Register',
                metricGroup: 'New User',
            },
            {
                config: moneyConfig, metric: 'new_register',
                frequencyBit: Properties.frequencyBit.daily, label: 'New Register',
                metricGroup: 'New User',
            },
            {
                config: moneyConfig, metric: 'ccu',
                frequencyBit: Properties.frequencyBit.realtime, label: 'Realtime CCU',
                metricGroup: 'User',
            },
            {
                config: moneyConfig, metric: 'new_txn_user',
                frequencyBit: Properties.frequencyBit.daily, label: 'New Txn User',
                metricGroup: 'New User',
            },
            {
                config: moneyConfig, metric: 'txn_user',
                frequencyBit: Properties.frequencyBit.daily, label: 'Txn User',
                metricGroup: 'Existing User',
            },
            {
                config: moneyConfig, metric: 'active',
                frequencyBit: Properties.frequencyBit.daily, label: 'DAU',
                metricGroup: 'User',
            },
        ], ({config, metric, frequencyBit}) => this.hasMetric(
            config.product, metric, frequencyBit
        ));
    }

    render() {
        let products = this.getProducts();
        return (
            <React.Fragment>
                {_.map(products, (productConfig) => (<Section key={'section' + productConfig.label}
                                                        productType={productConfig.product}
                                                        links={this.makeLinks(productConfig)}
                                                        label={productConfig.sidebar_label}
                                                        additionalData={this.props.additionalData}
                                               />))
                }
            </React.Fragment>
        )
    }
}

export {MoneySidebar};
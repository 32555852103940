import {AbstractRouter} from "../Router";
import {DailyReport} from "../DailyReport";
import {Properties} from "../../common/properties";
import {RealtimeReport} from "../RealtimeReport";

class CreditRouter extends AbstractRouter {

    getConfigDicts() {
        const txnConfigDicts = [
            {
                metric: 'txn',
                reportLabel: 'Txn',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'txn',
                reportLabel: 'Txn',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'txn_spl',
                reportLabel: 'Txn (SPayLater)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'txn_spl',
                reportLabel: 'Txn (SPayLater)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'txn_spl-offline',
                reportLabel: 'Txn (SPayLater - Offline)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'txn_spl-offline',
                reportLabel: 'Txn (SPayLater - Offline)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'txn_bcl',
                reportLabel: 'Txn (Buyer Cash Loan)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'txn_bcl',
                reportLabel: 'Txn (Buyer Cash Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'txn_scl',
                reportLabel: 'Txn (Seller Cash Loan)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'txn_scl',
                reportLabel: 'Txn (Seller Cash Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'txn_fel',
                reportLabel: 'Txn (Fast Escrow)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'txn_fel',
                reportLabel: 'Txn (Fast Escrow)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'txn_chp',
                reportLabel: 'Txn (Cicilan Handphone Loan)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'txn_chp',
                reportLabel: 'Txn (Cicilan Handphone Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
        ]
        const tpvConfigDicts = [
            {
                metric: 'tpv',
                reportLabel: 'Tpv',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'tpv',
                reportLabel: 'Tpv',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'tpv_spl',
                reportLabel: 'Tpv (SPayLater)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'tpv_spl',
                reportLabel: 'Tpv (SPayLater)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'tpv_spl-offline',
                reportLabel: 'Tpv (SPayLater - Offline)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'tpv_spl-offline',
                reportLabel: 'Tpv (SPayLater - Offline)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'tpv_bcl',
                reportLabel: 'Tpv (Buyer Cash Loan)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'tpv_bcl',
                reportLabel: 'Tpv (Buyer Cash Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'tpv_scl',
                reportLabel: 'Tpv (Seller Cash Loan)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'tpv_scl',
                reportLabel: 'Tpv (Seller Cash Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'tpv_fel',
                reportLabel: 'Tpv (Fast Escrow)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'tpv_fel',
                reportLabel: 'Tpv (Fast Escrow)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'tpv_chp',
                reportLabel: 'Tpv (Cicilan Handphone Loan)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'tpv_chp',
                reportLabel: 'Tpv (Cicilan Handphone Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
        ]
        const txnProportionConfigDicts = [
            {
                metric: 'txn_proportion',
                reportLabel: 'Gross Order %',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'txn_proportion_spl',
                reportLabel: 'Gross Order % (SPayLater)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'txn_proportion_spl-offline',
                reportLabel: 'Gross Order % (SPayLater - Offline)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'txn_proportion_bcl',
                reportLabel: 'Gross Order % (Buyer Cash Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'txn_proportion_scl',
                reportLabel: 'Gross Order % (Seller Cash Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'txn_proportion_fel',
                reportLabel: 'Gross Order % (Fast Escrow)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'txn_proportion_chp',
                reportLabel: 'Gross Order % (Cicilan Handphone Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
        ]
        const gmvProportionConfigDicts = [
            {
                metric: 'gmv_proportion',
                reportLabel: 'Gmv %',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'gmv_proportion_spl',
                reportLabel: 'Gmv % (SPayLater)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'gmv_proportion_spl-offline',
                reportLabel: 'Gmv % (SPayLater - Offline)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'gmv_proportion_bcl',
                reportLabel: 'Gmv % (Buyer Cash Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'gmv_proportion_scl',
                reportLabel: 'Gmv % (Seller Cash Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'gmv_proportion_fel',
                reportLabel: 'Gmv % (Fast Escrow)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'gmv_proportion_chp',
                reportLabel: 'Gmv % (Cicilan Handphone Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
        ]
        const loanOutstandingConfigDicts = [
            {
                metric: 'loan_outstanding',
                reportLabel: 'Loan O/S',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'loan_outstanding_spl',
                reportLabel: 'Loan O/S (SPayLater)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'loan_outstanding_spl-offline',
                reportLabel: 'Loan O/S (SPayLater - Offline)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'loan_outstanding_bcl',
                reportLabel: 'Loan O/S (Buyer Cash Loan)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'loan_outstanding_scl',
                reportLabel: 'Loan O/S (Seller Cash Loan)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'loan_outstanding_fel',
                reportLabel: 'Loan O/S (Fast Escrow)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'loan_outstanding_chp',
                reportLabel: 'Loan O/S (Cicilan Handphone Loan)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'loan_outstanding',
                reportLabel: 'Loan O/S',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'loan_outstanding_spl',
                reportLabel: 'Loan O/S (SPayLater)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'loan_outstanding_spl-offline',
                reportLabel: 'Loan O/S (SPayLater - Offline)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'loan_outstanding_bcl',
                reportLabel: 'Loan O/S (Buyer Cash Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'loan_outstanding_scl',
                reportLabel: 'Loan O/S (Seller Cash Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'loan_outstanding_fel',
                reportLabel: 'Loan O/S (Fast Escrow)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'loan_outstanding_chp',
                reportLabel: 'Loan O/S (Cicilan Handphone Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
        ]
        const loanOutstandingDiffConfigDicts = [
            {
                metric: 'loan_outstanding_diff',
                reportLabel: 'Loan O/S Diff',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'loan_outstanding_diff',
                reportLabel: 'Loan O/S Diff',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'loan_outstanding_diff_spl',
                reportLabel: 'Loan O/S Diff (SPayLater)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'loan_outstanding_diff_spl',
                reportLabel: 'Loan O/S Diff (SPayLater)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'loan_outstanding_diff_spl-offline',
                reportLabel: 'Loan O/S Diff (SPayLater - Offline)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'loan_outstanding_diff_spl-offline',
                reportLabel: 'Loan O/S Diff (SPayLater - Offline)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'loan_outstanding_diff_bcl',
                reportLabel: 'Loan O/S Diff (Buyer Cash Loan)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'loan_outstanding_diff_bcl',
                reportLabel: 'Loan O/S Diff (Buyer Cash Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'loan_outstanding_diff_scl',
                reportLabel: 'Loan O/S Diff (Seller Cash Loan)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'loan_outstanding_diff_scl',
                reportLabel: 'Loan O/S Diff (Seller Cash Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'loan_outstanding_diff_fel',
                reportLabel: 'Loan O/S Diff (Fast Escrow)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'loan_outstanding_diff_fel',
                reportLabel: 'Loan O/S Diff (Fast Escrow)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'loan_outstanding_diff_chp',
                reportLabel: 'Loan O/S Diff (Cicilan Handphone Loan)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'loan_outstanding_diff_chp',
                reportLabel: 'Loan O/S Diff (Cicilan Handphone Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
        ]
        const loanDisbursedConfigDicts = [
            {
                metric: 'loan_disbursed',
                reportLabel: 'Loan Disbursed',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'loan_disbursed',
                reportLabel: 'Loan Disbursed',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'loan_disbursed_spl',
                reportLabel: 'Loan Disbursed (SPayLater)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'loan_disbursed_spl',
                reportLabel: 'Loan Disbursed (SPayLater)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'loan_disbursed_spl-offline',
                reportLabel: 'Loan Disbursed (SPayLater - Offline)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'loan_disbursed_spl-offline',
                reportLabel: 'Loan Disbursed (SPayLater - Offline)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'loan_disbursed_bcl',
                reportLabel: 'Loan Disbursed (Buyer Cash Loan)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'loan_disbursed_bcl',
                reportLabel: 'Loan Disbursed (Buyer Cash Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'loan_disbursed_scl',
                reportLabel: 'Loan Disbursed (Seller Cash Loan)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'loan_disbursed_scl',
                reportLabel: 'Loan Disbursed (Seller Cash Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'loan_disbursed_fel',
                reportLabel: 'Loan Disbursed (Fast Escrow)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'loan_disbursed_fel',
                reportLabel: 'Loan Disbursed (Fast Escrow)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'loan_disbursed_chp',
                reportLabel: 'Loan Disbursed (Cicilan Handphone Loan)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'loan_disbursed_chp',
                reportLabel: 'Loan Disbursed (Cicilan Handphone Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
        ]
        const effectiveBorrowersConfigDicts = [
            {
                metric: 'effective_borrowers',
                reportLabel: 'Effective Borrowers',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'effective_borrowers',
                reportLabel: 'Effective Borrowers',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'effective_borrowers_spl',
                reportLabel: 'Effective Borrowers (SPayLater)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'effective_borrowers_spl',
                reportLabel: 'Effective Borrowers (SPayLater)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'effective_borrowers_spl-offline',
                reportLabel: 'Effective Borrowers (SPayLater - Offline)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'effective_borrowers_spl-offline',
                reportLabel: 'Effective Borrowers (SPayLater - Offline)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'effective_borrowers_bcl',
                reportLabel: 'Effective Borrowers (Buyer Cash Loan)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'effective_borrowers_bcl',
                reportLabel: 'Effective Borrowers (Buyer Cash Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'effective_borrowers_scl',
                reportLabel: 'Effective Borrowers (Seller Cash Loan)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'effective_borrowers_scl',
                reportLabel: 'Effective Borrowers (Seller Cash Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'effective_borrowers_fel',
                reportLabel: 'Effective Borrowers (Fast Escrow)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'effective_borrowers_fel',
                reportLabel: 'Effective Borrowers (Fast Escrow)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'effective_borrowers_chp',
                reportLabel: 'Effective Borrowers (Cicilan Handphone Loan)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'effective_borrowers_chp',
                reportLabel: 'Effective Borrowers (Cicilan Handphone Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
        ]
        const newBorrowersConfigDicts = [
            {
                metric: 'new_borrowers',
                reportLabel: 'New Borrowers',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'new_borrowers',
                reportLabel: 'New Borrowers',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'new_borrowers_spl',
                reportLabel: 'New Borrowers (SPayLater)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'new_borrowers_spl',
                reportLabel: 'New Borrowers (SPayLater)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'new_borrowers_spl-offline',
                reportLabel: 'New Borrowers (SPayLater - Offline)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'new_borrowers_spl-offline',
                reportLabel: 'New Borrowers (SPayLater - Offline)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'new_borrowers_bcl',
                reportLabel: 'New Borrowers (Buyer Cash Loan)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'new_borrowers_bcl',
                reportLabel: 'New Borrowers (Buyer Cash Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'new_borrowers_scl',
                reportLabel: 'New Borrowers (Seller Cash Loan)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'new_borrowers_scl',
                reportLabel: 'New Borrowers (Seller Cash Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'new_borrowers_fel',
                reportLabel: 'New Borrowers (Fast Escrow)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'new_borrowers_fel',
                reportLabel: 'New Borrowers (Fast Escrow)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'new_borrowers_chp',
                reportLabel: 'New Borrowers (Cicilan Handphone Loan)',
                frequency: Properties.frequencyBit.realtime,
                reportClass: RealtimeReport,
            },
            {
                metric: 'new_borrowers_chp',
                reportLabel: 'New Borrowers (Cicilan Handphone Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
        ]
        const outstandingBorrowersConfigDicts = [
            {
                metric: 'outstanding_borrowers',
                reportLabel: 'Outstanding Borrowers',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'outstanding_borrowers_spl',
                reportLabel: 'Outstanding Borrowers (SPayLater)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'outstanding_borrowers_spl-offline',
                reportLabel: 'Outstanding Borrowers (SPayLater - Offline)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'outstanding_borrowers_bcl',
                reportLabel: 'Outstanding Borrowers (Buyer Cash Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'outstanding_borrowers_scl',
                reportLabel: 'Outstanding Borrowers (Seller Cash Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'outstanding_borrowers_fel',
                reportLabel: 'Outstanding Borrowers (Fast Escrow)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
            {
                metric: 'outstanding_borrowers_chp',
                reportLabel: 'Outstanding Borrowers (Cicilan Handphone Loan)',
                frequency: Properties.frequencyBit.daily,
                reportClass: DailyReport,
            },
        ]

        const configDicts = [
            ...txnConfigDicts,
            ...tpvConfigDicts,
            ...txnProportionConfigDicts,
            ...gmvProportionConfigDicts,
            ...loanOutstandingConfigDicts,
            ...loanOutstandingDiffConfigDicts,
            ...loanDisbursedConfigDicts,
            ...effectiveBorrowersConfigDicts,
            ...newBorrowersConfigDicts,
            ...outstandingBorrowersConfigDicts,
        ];
        return configDicts.map(configDict => ({
            productGroup: 'credit_api',
            ...configDict
        }))
    }

    getMatchUrl() {
        return '/credit';
    }
}

export {CreditRouter};